<template>
  <modal-pro></modal-pro>
  <section class="section-full banner">
    <div class="box">
      <img src="./../../assets/banner-ps.png" alt="banner-ps" class="banner-img" />
      <div class="banner-sep"></div>
      <div class="row">
        <div class="col-50">
          <h2 class="title-special"><span class="colored">O</span>riginal</h2>
          <p v-html="$t('ps.text1')"></p>
        </div>
        <div class="col-50">
          <h2 class="title-special"><span class="colored">E</span>ssential</h2>
          <p v-html="$t('ps.text1')"></p>
        </div>
      </div>
      <scroll-down></scroll-down>
    </div>
  </section>
  <product v-for="(item, idx) in products" :key="idx" :product="item"></product>
  <product-range product="PS"></product-range>
</template>

<script>
import ScrollDown from "./../../components/ScrollDown.vue";
import ProductRange from "./../../components/ProductsRange.vue";
import Product from "./../../components/Product.vue";
import ModalPro from "./../../components/ModalPro.vue";

export default {
  name: "PS",
  components : { ScrollDown, ProductRange, Product, ModalPro},
  computed : {
    products() {
      return [
        {
          img : [
            require("@/assets/products/cypress.png"),
            require("@/assets/products/cypress2.png")
          ],
          bg : require("@/assets/products/cypress_bg.jpg"),
          name : "<span class='colored'>C</span>ypress",
          special :  true,
          video : "https://youtu.be/3TnxDqopsZE",
          bullets : [
            this.$t("cypress.bullet1"),
            this.$t("cypress.bullet2"),
            this.$t("cypress.bullet3")
          ]
        },
        {
          img : [
            require("@/assets/products/oak.png")
          ],
          bg : require("@/assets/products/oak_bg.jpg"),
          name : "<span class='colored'>O</span>ak",
          special :  true,
          video : "https://youtu.be/9eobXV5fzaI",
          bullets : [
            this.$t("oak.bullet1"),
            this.$t("oak.bullet2"),
            this.$t("oak.bullet3")
          ]
        },
        {
          img : [
            require("@/assets/products/cross_connector.png")
          ],
          bg : require("@/assets/products/oak_bg.jpg"),
          name : "Cross-connector",
          special :  false,
          video : null,
          bullets : [
            this.$t("connector.bullet1"),
            this.$t("connector.bullet2"),
            this.$t("connector.bullet3"),
            this.$t("connector.bullet4"),
            this.$t("connector.bullet5")
          ]
        },
        {
          img : [
            require("@/assets/products/cement.png")
          ],
          bg : require("@/assets/products/oak_bg.jpg"),
          name : this.$t("cement.title"),
          special :  false,
          video : null,
          bullets : [
            this.$t("cement.bullet1"),
            this.$t("cement.bullet2"),
            this.$t("cement.bullet3"),
            this.$t("cement.bullet4"),
            this.$t("cement.bullet5"),
            this.$t("cement.bullet6")
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

p {
  color:#fff;
  padding:0 20px;
  text-align: center;
  font-weight:400;
  font-size:22px;
}

h2 {
  color:#fff;
  font-size:80px;
  text-align: center;
  margin: 0 0 20px 0;
}

@media screen and (max-width:768px) {
  h2 {
    font-size:60px;
    margin:0;
    margin-top:10px;
  }

  p {
    font-size:14px;
    margin-bottom:80px;
  }
}

</style>